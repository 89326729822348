import * as React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MinistryQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            fields {
              slug
            }
            parent {
              id
              ... on File {
                name
                sourceInstanceName
              }
            }
            frontmatter {
              title
              category
              description
              book
              chapter
              author
              date
            }
          }
        }
      }
    }
  `)

  const items = data.allMarkdownRemark.edges
  const sermons = items.filter(
    item => item.node.frontmatter.category === "sermon"
  )

  console.log(sermons)

  return (
    <Layout>
      <div class="overflow-x-hidden bg-gradient-to-b from-yellow-500">
        <div class="px-6 py-8">
          <div class="container flex justify-between mx-auto">
            <div class="w-full lg:w-8/12">
              {sermons.map(sermon => {
                return (
                  <h1>Coming soon</h1>
                  // <div class="mt-6">
                  //   <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                  //     <div class="flex items-center justify-between">
                  //       <span class="font-light text-gray-600">
                  //         {sermon.node.frontmatter.date}
                  //       </span>
                  //     </div>
                  //     <div class="mt-2">
                  //       <a
                  //         href="#"
                  //         class="text-2xl font-bold text-gray-700 hover:underline"
                  //       >
                  //         {sermon.node.frontmatter.book}
                  //       </a>
                  //       <p class="mt-2 text-gray-600">
                  //         Chapter {sermon.node.frontmatter.chapter}
                  //       </p>
                  //     </div>
                  //     <div class="flex items-center justify-between mt-4">
                  //       <Link
                  //         to={sermon.node.fields.slug}
                  //         class="text-blue-500 hover:underline"
                  //       >
                  //         Listen
                  //       </Link>
                  //       <div>
                  //         <a href="#" class="flex items-center">
                  //           <img
                  //             src="/mitau.png"
                  //             alt="avatar"
                  //             class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block"
                  //           />
                  //           <h1 class="font-bold text-gray-700 hover:underline">
                  //             Michael Mitau
                  //           </h1>
                  //         </a>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                )
              })}
            </div>
            <div class="hidden w-4/12 -mx-8 lg:block">
              <div class="px-8">
                <h1 class="mb-4 text-xl font-bold text-gray-700">Pastors</h1>
                <div class="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white rounded-lg shadow-md">
                  <ul class="-mx-4">
                    <li class="flex items-center">
                      <img
                        src="/mitau.png"
                        alt="avatar"
                        class="object-cover w-10 h-10 mx-4 rounded-full"
                      />
                      <p>
                        <a
                          href="#"
                          class="mx-1 font-bold text-gray-700 hover:underline"
                        >
                          Michael Mitau
                        </a>
                        <span class="text-sm font-light text-gray-700">
                          2 Sermons
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
